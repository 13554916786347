<template>
  <div id="user-branch-setting">
    <v-data-table
      :headers="header"
      :items="userData"
      :options.sync="options"
      :items-per-page="options.itemsPerPage"
      :server-items-length="itemsLength"
      :loading="loading"
      :height="530"
      :search="inputSearch"
      :expanded.sync="itemExpands"
      class="elevation-1 mb-5"
      hide-default-footer
      single-line
      show-expand
      single-expand
      @update:page="optionsUpdated()"
      @update:sort-by="optionsUpdated()"
      @update:sort-desc="optionsUpdated()"
      @update:expanded="updateExpanded()"
      @page-count="setPageCount($event)">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="capitalize">
              User Permission
            </span>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical />
          <v-spacer />
          <v-autocomplete
            v-model="selectedRole"
            :items="roles"
            class="mr-5 search-text-field"
            label="Role"
            outlined
            dense
            hide-details />
          <v-text-field
            ref="searchTextField"
            v-model="inputSearch"
            append-icon="mdi-magnify"
            class="mr-5 search-text-field"
            label="Search"
            outlined
            dense
            hide-details
            @keydown.enter="searchData()" />
          <v-btn @click="searchData()">
            Search
          </v-btn>
          <v-dialog
            v-model="dialog"
            max-width="500px">
            <v-card>
              <validation-observer
                ref="attributeForm"
                tag="form"
                @submit.prevent="save()">
                <v-card-title>
                  <span class="headline">
                    {{ formTitle }}
                  </span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row v-if="dialog">
                      <v-col :cols="12">
                        <gw-text-field
                          v-model="formData.id"
                          :disabled="true"
                          label="ID" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-text-field
                          v-model="formData.email"
                          :disabled="true"
                          label="E-mail" />
                      </v-col>
                      <v-col :cols="12">
                        <gw-select
                          v-model="formData.role"
                          :items="roles"
                          :rules="rules.textFieldRules"
                          label="Roles" />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeFormModal()">
                    Cancel
                  </v-btn>
                  <v-btn
                    :loading="modalLoading"
                    color="blue darken-1"
                    type="submit"
                    text>
                    Save
                  </v-btn>
                </v-card-actions>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- expand -->
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          class=" py-4">
          <v-data-table
            :headers="branchHeaders"
            hide-default-footer
            disable-sort
            :items="currentUserBranchs"
            :items-per-page="999"
            class="elevation-1">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  <span class="capitalize">
                    {{ item.name }}
                  </span>
                </v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical />
                <v-spacer />
                <v-dialog
                  v-model="subDialog"
                  max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on">
                      Add
                    </v-btn>
                  </template>
                  <v-card>
                    <validation-observer
                      ref="userBranchForm"
                      tag="form"
                      @submit.prevent="addBranch()">
                      <v-card-title>
                        <span class="headline">
                          Add Branch
                        </span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col :cols="12">
                              <gw-autocomplete
                                v-model="formWarehouses"
                                :items="filteredWarehouses(currentUserBranchs, warehouses)"
                                item-text="name"
                                return-object
                                multiple
                                chips
                                small-chips
                                deletable-chips
                                :rules="rules.textFieldRules"
                                label="Warehouse" />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="closeFormModal()">
                          Cancel
                        </v-btn>
                        <v-btn
                          :loading="modalLoading"
                          color="blue darken-1"
                          type="submit"
                          text>
                          Save
                        </v-btn>
                      </v-card-actions>
                    </validation-observer>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="props">
              <gw-icon-hover
                small
                class="sku-table-bin"
                icon-name="mdi-delete"
                icon-hover="mdi-delete-empty"
                @click="removeUserBranch(props.item)" />
            </template>
          </v-data-table>
        </td>
      </template>
      <!-- end expand -->
      <template v-slot:[`item.picture`]="{ item }">
        <div class="pa-1">
          <v-avatar
            size="36px"
            class="avatar-image">
            <picture v-if="item.picture">
              <source :srcset="item.picture">
              <img
                src="@/assets/logo.png">
            </picture>
            <v-icon
              v-else
              color="primary"
              v-text="item.name" />
          </v-avatar>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <gw-icon-hover
          small
          class="sku-table-bin"
          icon-name="mdi-delete"
          icon-hover="mdi-delete-empty"
          @click="removeUser(item)" />
      </template>
    </v-data-table>
    <v-pagination
      v-if="paginationEnable"
      v-model="options.page"
      :length="pageCount"
      :disabled="loading"
      total-visible="7" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UserProvider from '@/resources/UserProvider'
import WarehouseProvider from '@/resources/WarehouseProvider'

const UserService = new UserProvider()
const WarehouseService = new WarehouseProvider()

export default {
  data () {
    return {
      inputSearch: '',
      selectedRole: null,
      dialog: false,
      subDialog: false,
      loading: false,
      itemsLength: 0,
      pageCount: 0,
      itemExpands: [],
      currentUserBranchs: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['id'],
        sortDesc: [true]
      },
      branchHeaders: [
        {
          text: 'Id',
          value: 'warehouse.id',
          width: 100
        },
        {
          text: 'Code',
          value: 'warehouse.code',
          width: 100
        },
        {
          text: 'Name',
          value: 'warehouse.name',
          width: 100
        },
        {
          text: 'OMS Warehouse',
          value: 'sellsukiWarehouse.code',
          width: 100
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 60,
          align: 'end'
        }
      ],
      roles: [
        'management',
        'area_manager',
        'marketing',
        'developer',
        'designer',
        'inventory_and_costing',
        'merchandising_planner',
        'merchandiser',
        'creative',
        'graphic',
        'online_admin',
        'office_admin',
        'accounting',
        'accounting_manager',
        'vm',
        'support',
        'warehouse_manager',
        'warehouse',
        'store_manager',
        'acting_assist_store_manager',
        'assist_store_manager',
        'sales_staff',
        'to_be_assigned'
      ],
      header: [
        {
          text: 'Picture',
          value: 'picture',
          width: 100
        },
        {
          text: 'ID',
          value: 'userId',
          width: 40
        },
        {
          text: 'E-mail',
          value: 'email',
          width: 100
        },
        {
          text: 'Role',
          value: 'role',
          width: 100
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: 60,
          align: 'end'
        }
      ],
      userData: [],
      warehouses: [],
      isEdited: false,
      searchTimeout: null,
      formWarehouses: [],
      formData: {},
      rules: {
        textFieldRules: {
          required: true
        }
      },
      getting: false,
      modalLoading: false
    }
  },
  computed: {
    formTitle () {
      return this.isEdited ? 'Edit User Permission' : 'New User Permission'
    },
    search () {
      return this.$route.query.search ? this.$route.query.search : ''
    },
    selectedRoleCompute () {
      return this.$route.query.role ? this.$route.query.role : null
    },
    paginationEnable () {
      return this.pageCount > 1
    }
  },
  watch: {
    selectedRole () {
      this.searchData()
    }
  },
  created () {
    this.getUser(this.options)
    this.getWarehouse()
    this.inputSearch = this.search
    this.selectedRole = this.selectedRoleCompute
  },
  mounted () {
    if (this.search) {
      this.$refs.searchTextField.$refs.input.focus()
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setErrorPage: 'Components/setErrorPage',
      setModal: 'Components/setModal',
      setWarehouse: 'Store/setWarehouse'
    }),
    filteredWarehouses (currentUserBranchs, warehouses) {
      const userBranchs = currentUserBranchs.map((u) => u.warehouse.id)

      return warehouses.filter((w) => !userBranchs.some((b) => b === w.id))
    },
    updateExpanded () {
      if (this.itemExpands[0]) {
        this.currentUserBranchs = []
        this.getUserBranch()
      }
    },
    setPageCount (event) {
      this.pageCount = event
    },
    optionsUpdated () {
      this.getUser(this.options)
    },
    removeUserBranch (item) {
      this.setModal({
        value: true,
        title: 'Remove User Branch',
        message: 'Do you want to remove the user branch',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveUserBranch(item.id)
      })
    },
    searchData () {
      this.options.page = 1
      this.$router.push({
        name: this.$route.name,
        query: { search: this.inputSearch, role: this.selectedRole }
      }).catch(() => {})

      this.getUser(this.options)
    },
    async getUserBranch () {
      try {
        this.getting = true
        this.loading = true
        const { data } = await UserService.getUserBranch(this.itemExpands[0].userId)
        this.currentUserBranchs = data
      } catch (error) {
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
        this.getting = false
      }
    },
    async getWarehouse () {
      try {
        this.getting = true
        this.loading = true
        const { data } = await WarehouseService.getWarehouse()
        this.warehouses = data
        this.setWarehouse(this.warehouseData)
      } catch (error) {
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
        this.getting = false
      }
    },
    async getUser (options) {
      if (!this.getting) {
        try {
          this.getting = true
          this.loading = true
          const { data } = await UserService.getUsers({
            ...options,
            search: this.search,
            role: this.selectedRoleCompute
          })
          this.itemsLength = data.total
          this.pageCount = data.pages
          this.userData = data.results
        } catch (error) {
          this.setErrorPage(error.code)
        } finally {
          this.loading = false
          this.getting = false
        }
      }
    },
    closeFormModal () {
      this.dialog = false
      this.isEdited = false
      this.subDialog = false
      this.formData = {}
      this.formWarehouses = []
    },
    editItem (item) {
      this.isEdited = true
      this.formData = {
        id: item.id,
        role: item.role,
        email: item.email,
        picture: item.picture
      }
      this.dialog = true
    },
    async addBranch () {
      try {
        this.modalLoading = true
        const vaid = await this.$refs.userBranchForm.validate()

        if (vaid) {
          await Promise.all(this.formWarehouses.map((w) => {
            const payload = {
              warehouse: w,
              sellsukiWarehouse: w?.warehouse?.sellsukiWarehouse || null,
              user: {
                id: this.itemExpands[0].userId,
                email: this.itemExpands[0].email
              }
            }

            if (!payload.sellsukiWarehouse) {
              delete payload.sellsukiWarehouse
            }

            return UserService.createUserBranch(payload)
          }))

          this.setSnackbar({
            value: true,
            message: this.$t('snackbar.UserBranch success'),
            type: 'success'
          })
          this.closeFormModal()
        }
      } catch (error) {
        console.error('addBranch', error)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.modalLoading = false
        this.getUserBranch()
      }
    },
    async save () {
      this.modalLoading = true
      const vaid = await this.$refs.attributeForm.validate()
      if (vaid) {
        let isSuscess = false
        try {
          isSuscess = await this.updateUser(this.formData)
          this.closeFormModal()
        } catch (error) {
          this.setSnackbar({
            value: true,
            message: `Error code ${error.code}: ${error.message}`,
            type: 'error'
          })
        } finally {
          if (isSuscess) {
            this.setSnackbar({
              value: true,
              message: this.$t('snackbar.UserBranch updated'),
              type: 'success'
            })
            this.getUser(this.options)
          }
        }
      }
      this.modalLoading = false
    },
    async updateUser (item) {
      const { data } = await UserService.updateUser(item.id, item)
      return !!data
    },
    async removeUser (item) {
      this.setModal({
        value: true,
        title: 'Remove User',
        message: 'Do you want to remove the user',
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.confirmRemoveUser(item.id)
      })
    },
    async confirmRemoveUserBranch (id) {
      try {
        const { data } = await UserService.deleteUserBranch(id)
        if (data.id && data.status === 'deleted') {
          this.setSnackbar({
            value: true,
            message: this.$t('snackbar.User Branch removed'),
            type: 'success'
          })
          if (this.options.page > 1 && this.userData.length < 2) {
            this.options.page--
          } else {
            this.getUserBranch()
          }
        } else {
          const error = {
            code: 400,
            message: 'User Branch ID not found or status not change'
          }
          throw error
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}`,
          type: 'error'
        })
      }
    },
    async confirmRemoveUser (id) {
      try {
        const { data } = await UserService.deleteUser(id)
        if (data.id && data.status === 'deleted') {
          this.setSnackbar({
            value: true,
            message: this.$t('snackbar.User removed'),
            type: 'success'
          })
          if (this.options.page > 1 && this.userData.length < 2) {
            this.options.page--
          } else {
            this.getUser(this.options)
          }
        } else {
          const error = {
            code: 400,
            message: 'Shipping ID not found or status not change'
          }
          throw error
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code}`,
          type: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-image {
  display: block;
}
</style>
